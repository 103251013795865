.layoutscroll {
    overflow-x: scroll;
    /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.layoutscroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.layoutscroll {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.table-striped-custom-row {
    background-color: rgba(255, 0, 0, 0.1) !important;
}

.table-striped-custom-row:hover {
    background-color: rgba(255, 0, 0, 0.2) !important;
}