.write-email-content {
    padding-bottom: 60px; 
}

.write-email-actions {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    background-color: #fff;
    border-top: 1px solid #ddd;
}
