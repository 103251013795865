.current-page {
	font-size: 1.5rem;
	vertical-align: middle;
}

.searchBox {
	/* margin: 0px 800px 0px 215px;
	position: relative;
	left: "29%"; */
	border: none !important;
	border-radius: 20px !important;
	padding: 10px !important;
	/* width: "26%"; */
}

table {
	width: 100%;
	overflow-y: hidden;
}

.details-small-card {
	border: "0.1px solid black" !important;
	width: "90px" !important;
	height: "62px" !important;
	padding: '15px' !important;
	margin-left: "5px" !important;
}

.user {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.user__body {
	padding-left: 20px;
	padding: 20px;
}

.current-page {
	font-size: 1.5rem;
	vertical-align: middle;
}

.pagination-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 10 !important;
}

/* Override some Bootstrap pagination styles */
ul.pagination {
	margin-top: 0;
	margin-bottom: 0;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

ul.pagination li.page-item.active a.page-link {
	color: #445565 !important;
	background-color: #e3e7eb !important;
	border-color: #ced4da !important;
}

ul.pagination a.page-link {
	padding: 0.75rem 1rem;
	min-width: 3.5rem;
	text-align: center;
	box-shadow: none !important;
	border-color: #ced4da !important;
	color: #6b88a4;
	font-weight: 900;
	font-size: 1rem;
}

ul.pagination a.page-link:hover {
	background-color: #f4f4f4;
}
/* .custom_text_area {
	width: 100%;
	border: ;
} */

.custom_text_area {
	display: flex;
	padding: 0;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}
.hv{
	display: flex;
	text-decoration-color: blue;
}
.hv:hover{
	text-decoration-color: blue;
}

.image-upload-consultant .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .image-upload-consultant  .inputfile + label {
    font-size: 1em;
    font-weight: 600;
    color: white;
    background-color: black;
    padding:10px 10px;
    display: inline-block;
    cursor: pointer;
    border-radius: 5px;
}

.image-upload-consultant .inputfile:focus + label,
.image-upload-consultant .inputfile + label:hover {
    background-color: red;
}

.profile-edit{
	/* position: absolute;
	top:15px;
	left:85px; */
}