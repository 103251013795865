// 
// _dropdown.scss
// 

.dropdown-menu {
  box-shadow: $box-shadow;
  animation-name: DropDownSlide;
  animation-duration: .3s;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;

  &.show {
    inset: 49px -6px auto auto !important
  }
}

.dropdown-menu-end[style] {
  left: -15vw !important;

}

.dropdown-menu custom-dropdown-menu {
  inset: 0px -13px auto auto !important;
}

.custom-dropdown-actionMenu {
  &.show {
    inset: auto 0px auto -16px !important;
  }
}
.custom-dropdown-pagination{
  &.show {
    left:-22px !important;
    bottom:1px !important;
  }
}

.dropdown-menu-right[style] {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu[data-popper-placement^=right],
.dropdown-menu[data-popper-placement^=top],
.dropdown-menu[data-popper-placement^=left] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px)
  }
}

.dropdown-menu-xxl {
  max-height: 90vh;
  overflow-y: scroll;
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-xxl {
    width: 80vw;
    overflow-y: hidden;
  }

  .dropdown-menu-map {
    width: 720px;
    min-height: 80vh;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: $border-color;
}

// Dropdown size

.dropdown-mega-menu-xl {
  width: 40rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}

.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  left: 0;
  transform: translate3d(5px, 5px, 0px) !important;
}