.folder-item {
    margin: 5px 0;
    padding: 5px 10px;
    border: 1px solid #fcfaf1;
    border-radius: 4px;
    font-size: 14px;
    background-color: #c3d3d3;
    ;
    cursor: pointer;

    .folder-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            flex: 1;
            margin-right: 5px;
        }

        .icon-container {
            display: flex;
            align-items: center;

            .edit-icon,
            .add-icon,
            .delete-icon {
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }

    .child-folder-input {
        margin-left: 20px;
        margin-top: 5px;
    }
}

.create-category {
    background-color: #ebebeb;
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: #e0e0e0;
    }

    .icon {
        margin-right: 5px;
    }
}

.category-creation-input {
    margin-top: 10px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}


.clickable {
    cursor: pointer;
}