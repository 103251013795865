.email_modal-minimizable-container {
    position: fixed;
    transition: all 0.5s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 1000;
 
  }
 
  .email_minimizable-content-body {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
 
  .email_modal-minimizable-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #f1f1f1;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    z-index: 10;
  }
 
  .email_modal-minimizable-content {
    flex: 1;
    padding: 20px 20px 0px 20px;
    overflow-y: auto;
  }
 
  .email_full-screen {
    bottom: 0;
    right: 0px;
    width: 50vw;
    height: calc(100vh - 75px);
    max-width: 680px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }
 
  .email_minimized {
    bottom: 0px;
    right: 0px;
    width: 50vw;
    max-width: 680px;
    height: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }
 
 
 
  @media (max-width: 500px) {
    .email_minimized {
      width: 100%;
      right: 0;
    }
  }