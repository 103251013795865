.sidebar-main {
  // position: fixed;
  overflow: scroll;
  height: 100vh;
  background-color: #fff;
  // top: 0;
  padding-top: 10px;
  width: 240px;
  z-index: 5;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar-main::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar-main {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@keyframes slideIn {
  0% {
    transform: translateX(0px);
    width: 70px;
  }

  100% {
    transform: translateX(0px);
    width: 260px;
  }
}

// .sidebar-main {
//   animation: slideIn 0.5s ease-in-out 0s forwards;
// }

.menu-label {
  font-size: 15px;
}

@keyframes slideInContent {
  0% {
    // transform: translateX(-190px);
    max-width: 0%;
    overflow: hidden;
  }

  100% {
    max-width: 100%;
    overflow: visible;
  }
}

.sidebar-main-content {
  // animation: slideInContent 0.5s ease-in 0.1s forwards;
  width: 240px;
}

.sidebar-main-closed {
  // position: fixed;
  height: 100vh;
  background-color: #fff;
  top: 0;
  padding-top: 20px;
  width: 70px;
  z-index: 5;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.08);
}

@keyframes slideInClosed {
  0% {
    transform: translateX(0px);

    // width: 260px;
  }

  100% {
    transform: translateX(0px);

    // width: 70px;
  }
}

.sidebar-main-closed {
  animation: slideInClosed 0.5s ease-in-out 0s forwards;
}

.link-style {
  margin-top: "30px";
  color: "black";
  padding: "10px";
  border-radius: "8px";
  color: "black";
}

@keyframes slideMargin {
  0% {
    margin-left: 0px;
  }

  100% {
    // margin-left: 190px;
  }
}

.content-slideMargin {
  animation: slideMargin 0.5s ease-in-out 0s forwards;
}

@keyframes slideMarginReverse {
  0% {
    // margin-left: 190px;
  }

  100% {
    margin-left: 0px;
  }
}

.content-slideMarginReverse {
  animation: slideMarginReverse 0.5s ease-in-out 0s forwards;
}

.map-card {
  min-height: 93vh;
  position: absolute;
  left: 40px;
  min-width: 40vw;
  z-index: 1000;
  box-shadow: -10px 2px 10px rgba(112, 112, 112, 0.2);
  background-color: white;
  border-radius: 20px 0px 0px 20px;
  padding: 20px;

  -webkit-animation: slide1 0.5s forwards;
  -webkit-animation-delay: 0s;
  animation: slide1 0.5s forwards;
  animation-delay: 0s;
}

@media (max-width: 1175px) {
  .map-card {
    min-height: 91vh;
    left: 40px;
    min-width: 60vw;

    -webkit-animation: slide2 0.5s forwards;
    -webkit-animation-delay: 0s;
    animation: slide2 0.5s forwards;
    animation-delay: 0s;
  }
}

@media (max-width: 875px) {
  .map-card {
    min-height: 91vh;
    left: 40px;
    min-width: 80vw;

    -webkit-animation: slide3 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide3 0.5s forwards;
    animation-delay: 0s;
  }
}

.map-card-hide {
  position: absolute;
  left: -40.2vw;
  box-shadow: 0px 0px 0px rgba(112, 112, 112, 0);
  -webkit-animation: slide4 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide4 0.5s forwards;
  animation-delay: 0s;
}