.payout-detail-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;  
}

.scroll-content{
    display: flex;
    flex-direction: column;
    flex-grow: 1 !important;
    overflow-y: auto;
}

.nav-tabs{
    background: #F5F7F8;
} 

.preview-section {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.preview-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.preview-item img {
    transition: transform 0.2s ease-in-out;
}

.preview-item img:hover {
    transform: scale(1.1);
}

 
