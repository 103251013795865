.image-upload {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .image-upload--active {
    background-color: #f0f0f0;
  }
/*   
  .image-upload__preview {
    position: relative;
    width: 100%;
    height: 100%;
  } */
  .pencil-icon {
      position: absolute;
      top: 65%;
      left: 65%;
      font-size: 14px;
      height: 20px;
      width: 20px;
      text-align: center;
      border-radius: 300px;
      z-index: 1;
      color: #ccc;
      background-color: black;
  }
  .image-upload__overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    opacity: 0.7;
    cursor: pointer;
  }
  
  .image-upload__preview {
   width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    object-fit: cover;
    border-radius: 50%;
    clip-path: circle(50%);
  }
  
  .image-upload p {
    margin: 0;
    padding: 10px;
  }
  