.profile-container {
    display: flex;
}

.avatar-wrapper {
    position: relative;
}

.avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid gray;
}

.edit-button {
    position: absolute;
    top: 0px;
    right: 0;
    padding: 0.35rem;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #4a4a4a;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.edit-button:hover {
    background-color: #e4e3e3;
}

.profile-name {
    color: white;
    font-weight: bold;
    margin-top: 1.5rem;
}

.profile-title {
    color: gray;
    font-size: 0.75rem;
    margin-top: 0.5rem;
}

.modal-i {
    position: relative;
    z-index: 10;
}

.modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.35);
    transition: all 0.3s ease-in-out;
}

.modal-container {
    position: fixed;
    inset: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
    overflow-y: auto;
}

.modal-content-i {
    position: relative;
    width: 95%;
    max-width: 700px;
    background-color: #FFF;
    color: #f1f1f1;
    border-radius: 1rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    padding: 1rem;
    overflow: scroll;
    max-height: 100vh;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
}

.close-button-i {
    padding: 0.5rem;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    color: #b0b0b0;
    cursor: pointer;
}

.close-button-i:hover {
    background-color: #3a3a3a;
}

.close-button-i:focus {
    outline: none;
}

.file-input-label {
    display: block;
    margin-bottom: 0.75rem;
}

.file-input {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    color: #6b7280;
    cursor: pointer;
}

.file-input::file-selector-button {
    margin-right: 1rem;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    border: none;
    font-size: 0.75rem;
    background-color: #5f5f5f;
    color: #fff;
}

.file-input::file-selector-button:hover {
    background-color: #4b5563;
}

.error-message {
    color: #f87171;
    font-size: 0.75rem;
}

.crop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.crop-image {
    max-width: 100%;
    max-height: 60vh;
    object-fit: contain;
}

.crop-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-family: monospace;
    font-size: 0.875rem;
    background-color: #ffca59;
    color: rgb(0, 0, 0);
    cursor: pointer;
    border: none;
    margin-bottom: 50px;
}

.crop-button:hover {
    background-color: #e5af3b;
}

.crop-preview {
    margin-top: 1rem;
    display: none;
    border: 1px solid black;
    object-fit: contain;
    width: 120px;
    height: 120px;
}

.drag-area {
    border: 2px dashed #fff;
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: rgba(229, 175, 59, 0.3);
    padding: 20px;
}

.drag-area.active {
    border: 2px solid #fff;
}

.drag-area .icon {
    font-size: 100px;
    color: #e5af3b;
}

.drag-area header {
    font-size: 15px;
    font-weight: 500;
    color: #e5af3b;
}

.drag-area span {
    font-size: 15px;
    font-weight: 500;
    color: #e5af3b;
    margin: 10px 0 15px 0;
}

.drag-area button {
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 500;
    border: none;
    outline: none;
    background-color: #fff;
    color: #e5af3b;
    border-radius: 5px;
    cursor: pointer;
}

.drag-area img.preview-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}